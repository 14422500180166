
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: '404',
  components: {
    Head
  }
})
