<template>
  <Head>
    <title>404 - this site does not exist.</title>
    <meta name="robots" content="noindex">
  </Head>
  <div class="w-full grid grid-cols-8 mb-16 mt-16">
    <div class="col-start-2 col-end-8">
      <h1 class="font-sen font-bold text-2xl mb-8" v-html="$t('message.Error.title')"></h1>
      <p class="font-source text-md max-w-prose" v-html="$t('message.Error.text')"></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: '404',
  components: {
    Head
  }
})
</script>

<style scoped>

</style>
